import { ThemeProvider } from "@emotion/react";
import { ColorModeContext, useMode } from "./theme";
import { CssBaseline } from "@mui/material";
import MainTopbar from "./pages/common/MainTopbar";
import { Route, Routes } from "react-router-dom";
import Dashboard from "./pages/dashboard";
import UserPage from "./pages/user";
import PurchasePage from "./pages/purchase";
import MainSidebar from "./pages/common/MainSidebar";
import Login from "./pages/login";
import { AuthProvider } from "./hooks/useAuth";
import { ProtectedRoute } from "./pages/common/ProtectedRoute";

function App() {
  const [theme, colorMode] = useMode();

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline></CssBaseline>
        <AuthProvider>
          <Routes>
            <Route path="/" element={<Login/>}></Route>
            <Route
              path="/dash-board"
              element={
                <div className="app">
                  <MainSidebar />
                  <main className="content">
                    <MainTopbar />
                    <ProtectedRoute>
                      <Dashboard />
                    </ProtectedRoute>
                  </main>
                </div>
              }
            ></Route>
            <Route
              path="/users"
              element={
                <div className="app">
                  <MainSidebar />
                  <main className="content">
                    <MainTopbar />
                    <ProtectedRoute>
                      <UserPage />
                    </ProtectedRoute>
                  </main>
                </div>
              }
            ></Route>
            <Route
              path="/purchases"
              element={
                <div className="app">
                  <MainSidebar />
                  <main className="content">
                    <MainTopbar />
                    <ProtectedRoute>
                      <PurchasePage />
                    </ProtectedRoute>
                  </main>
                </div>
              }
            ></Route>
          </Routes>
        </AuthProvider>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
