import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import MainHeader from "../common/MainHeader";
import { Box, Button, styled } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import SwitchConfirm from "./SwitchConfirm";
import ButtonDelete from "./ButtonDelete";
import "../user/index.css";
import { useEffect, useState } from "react";
import axios, {
  setAccessTokenHeader,
  setLogoutToAxios,
} from "../../network/ApiClient";
import { getAllUser } from "../../network/ApiConstants";
import { useAuth } from "../../hooks/useAuth";

function customCheckbox(theme) {
  return {
    "& .MuiCheckbox-root svg": {
      width: 16,
      height: 16,
      backgroundColor: "transparent",
      border: `1px solid ${
        theme.palette.mode === "light" ? "#d9d9d9" : "rgb(67, 67, 67)"
      }`,
      borderRadius: 2,
    },
    "& .MuiCheckbox-root svg path": {
      display: "none",
    },
    "& .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg": {
      backgroundColor: "white",
      borderColor: "white",
    },
    "& .MuiCheckbox-root.Mui-checked .MuiIconButton-label:after": {
      position: "absolute",
      display: "block",
      content: '""',
      width: 6,
      height: 12,
      border: "solid #2196F3",
      borderWidth: "0 2px 2px 0",
      transform: "rotate(45deg)",
      top: "2px",
      left: "5px",
    },
    "& .MuiCheckbox-root.MuiCheckbox-indeterminate .MuiIconButton-label:after":
      {
        width: 8,
        height: 8,
        backgroundColor: "white",
        transform: "none",
        top: "39%",
        border: 0,
      },
  };
}

const StyleDataGrid = styled(DataGrid)(({ theme }) => ({
  ...customCheckbox(theme),
}));

const UserPage = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [user, setUser] = useState([]);
  const { token, logout } = useAuth();

  useEffect(() => {
    if (token) {
      setAccessTokenHeader(token);
      setLogoutToAxios(logout);
      fetchUsers();
    }
  }, [token, logout]);

  const fetchUsers = async () => {
    try {
      const response = await axios.get(getAllUser);

      var listFilterUser = response.data.data.users.filter(
        (user) => user.status == 1 || user.status == 0
      );
      setUser(listFilterUser);
    } catch (error) {
      console.log(error);
    }
  };

  const deleteUserWithId = async (id) => {
    try {
      const response = await axios.delete(getAllUser + `/${id}`);
      if (response.status == 200) {
        await fetchUsers();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const columns = [
    {
      field: "id",
      flex: 0.2,
      headerName: "ID",
    },
    {
      field: "username",
      headerName: "Username",
      flex: 1,
      type: "string",
      align: "left",
      headerAlign: "left",
      cellClassName: "usename-column--cell",
    },
    {
      field: "email",
      headerName: "Email",
      align: "left",
      flex: 1,
      cellClassName: "email-column--cell",
    },
    {
      field: "created_at",
      headerName: "Create At",
      flex: 1,
      align: "center",
      headerAlign: "center",
      cellClassName: "create-at-column--cell",
    },
    {
      field: "balance",
      headerName: "Balance",
      flex: 1,
      headerAlign: "center",
      align: "center",
      cellClassName: "balance-column--cell",
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.5,
      headerAlign: "center",
      align: "center",
      cellClassName: "active-column--cell",
      renderCell: (params) => {
        return <SwitchConfirm value={params.value} userId={params.row.id} />;
      },
    },
    {
      headerName: "",
      field: "edit",
      flex: 0.5,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return <Button>Edit</Button>;
      },
    },
    {
      headerName: "",
      field: "delete",
      flex: 0.5,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return <ButtonDelete value={params} deleteAction={deleteUserWithId} />;
      },
    },
  ];

  return (
    <Box m="20px">
      <MainHeader title="Users" subtitle="Managing the users"></MainHeader>
      <Box m="40px 0 0 0" height="75vh">
        <DataGrid
          checkboxSelection
          disableRowSelectionOnClick
          rows={user}
          columns={columns}
        ></DataGrid>
      </Box>
    </Box>
  );
};

export default UserPage;
