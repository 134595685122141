import { useEffect, useState } from "react";
import { useAuth } from "../../hooks/useAuth";
import axios, {
  setAccessTokenHeader,
  setLogoutToAxios,
} from "../../network/ApiClient";
import { useNavigate } from "react-router-dom";
import { loginApi } from "../../network/ApiConstants";
import "../login/LoginStyle.css";
import { Button } from "@mui/material";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { login, token, logout } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      setAccessTokenHeader(token);
      setLogoutToAxios(logout);
      navigate("/dash-board", { replace: true });
    }
  }, [navigate, token, logout]);

  const onChangeUsername = (event) => {
    setUsername(event.target.value.toString());
  };

  const onChangePassword = (event) => {
    setPassword(event.target.value.toString());
  };

  const handleSignIn = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(
        loginApi,
        JSON.stringify({ username, password })
      );
      var accessToken = response?.data?.data?.token;
      var user = response?.data?.data?.user;
      await login(accessToken, user);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="scaffold">
      <h1 className="header-login">ADMIN MTC TOOL</h1>

      <form onSubmit={handleSignIn}>
        <div className="username-field">
          <h3 className="title">User name</h3>
          <input
            className="input-field"
            type="text"
            placeholder="Username"
            onChange={(event) => onChangeUsername(event)}
          />
        </div>

        <div className="password-field">
          <h3 className="title">Password</h3>
          <input
            className="input-field"
            type="password"
            placeholder="Password"
            onChange={(event) => onChangePassword(event)}
          />
        </div>
        <div className="login-button-field">
          <Button
            className="signin-btn"
            variant="contained"
            onClick={handleSignIn}
          >
            Sign In
          </Button>
        </div>
      </form>
    </div>
  );
};

export default Login;
