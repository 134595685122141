import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MainHeader from "../common/MainHeader";
import { useEffect, useState } from "react";
import { useAuth } from "../../hooks/useAuth";
import axios, {
  setAccessTokenHeader,
  setLogoutToAxios,
} from "../../network/ApiClient";
import { getDashBoardApi } from "../../network/ApiConstants";
const Dashboard = () => {
  const [filterAttribute, setFilterAttribute] = useState(12);
  const [dashBoardData, setDashBoardData] = useState(null);
  const [listPurchaseMonth, setListPurchaseMonth] = useState(null);
  const [listUserMonth, setListUserMonth] = useState(null);
  const [listMonthFilter, setListMonthFilter] = useState([]);

  const [totalUser, setTotalUser] = useState(null);
  const [totalPurchase, setTotalPurchase] = useState(null);
  const { token, logout } = useAuth();

  const MONTH_NAMES = [
    "Tháng 1",
    "Tháng 2",
    "Tháng 3",
    "Tháng 4",
    "Tháng 5",
    "Tháng 6",
    "Tháng 7",
    "Tháng 8",
    "Tháng 9",
    "Tháng 10",
    "Tháng 11",
    "Tháng 12",
    "All",
  ];

  useEffect(() => {
    if (token) {
      setAccessTokenHeader(token);
      setLogoutToAxios(logout);
      fetchDataDashBoard();
    }
  }, [token, logout]);

  const fetchDataDashBoard = async () => {
    try {
      const response = await axios.get(getDashBoardApi);
      const monthPurchases = response.data.data.stats.purchases;
      const monthUsers = response.data.data.stats.users;

      var purchaseObj = {};
      purchaseObj.totalKey = response.data.data.purchase.total_keys;
      purchaseObj.totalPurchase = response.data.data.purchase.total;

      var listMonths = [12]; // 12 is
      if (monthPurchases.length >= monthUsers) {
        monthPurchases.forEach((purchase) => {
          const date = new Date(purchase.month);
          const monthNumber = date.getUTCMonth(); // getUTCMonth() returns months from 0-11
          listMonths.push(monthNumber);
        });
      } else {
        monthUsers.forEach((user) => {
          const date = new Date(user.month);
          const monthNumber = date.getUTCMonth();
          listMonths.push(monthNumber);
        });
      }

      // console.log(listMonths);
      // console.log(response.data.data);
      // console.log(monthPurchases);
      // console.log(monthUsers);
      // console.log(purchaseObj);

      setListMonthFilter(listMonths);

      setListPurchaseMonth(monthPurchases);
      setListUserMonth(monthUsers);

      setTotalUser(response.data.data.user.total);
      setTotalPurchase(purchaseObj);

      setDashBoardData(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (event) => {
    setFilterAttribute(event.target.value);

    var newPurchaseObj = {};
    if (event.target.value != 12) {
      listPurchaseMonth.forEach((purchase) => {
        const date = new Date(purchase.month);
        if (event.target.value == date.getUTCMonth()) {
          newPurchaseObj.totalKey = purchase.totalAmount;
          newPurchaseObj.totalPurchase = purchase.purchaseCount;
          setTotalPurchase(newPurchaseObj);
        }
      });
      
      // check for not having any purchase
      if (Object.keys(newPurchaseObj).length == 0) {
        newPurchaseObj.totalKey = 0;
        newPurchaseObj.totalPurchase = 0;
        setTotalPurchase(newPurchaseObj);
      }

      listUserMonth.forEach((user) => {
        const date = new Date(user.month);
        if (event.target.value == date.getUTCMonth()) {
          setTotalUser(user.new_users);
        }
      });
    } else {
      newPurchaseObj.totalKey = dashBoardData.purchase.total_keys;
      newPurchaseObj.totalPurchase = dashBoardData.purchase.total;
      setTotalUser(dashBoardData.user.total);
      setTotalPurchase(newPurchaseObj);
    }
  };

  return (
    <Box m="20px">
      <MainHeader
        title="DASHBOARD"
        subtitle="Welcome to your dashboard"
      ></MainHeader>
      <Box sx={{ minWidth: 120 }}>
        <div className="row">
          <div className="sort-label" style={{ padding: "10px" }}>
            Sort
          </div>
          <FormControl sx={{ minWidth: 120 }}>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={filterAttribute}
              onChange={handleChange}
            >
              {listMonthFilter.map((month) => {
                return <MenuItem value={month}>{MONTH_NAMES[month]}</MenuItem>;
              })}
            </Select>
          </FormControl>
        </div>
      </Box>
      <Box>
        <div className="row">
          <div className="sort-label" style={{ padding: "10px" }}>
            Tổng User:
          </div>
          <h3 className="user-value">{totalUser ?? 0}</h3>
        </div>
        <div
          className="row"
          style={filterAttribute != 12 ? { display: "none" } : null}
        >
          <div
            className="sort-label"
            style={{ paddingLeft: "30px", paddingRight: "10px" }}
          >
            User active:
          </div>
          <h3 className="user-value">
            {dashBoardData?.user?.active_user ?? 0}
          </h3>
        </div>
        <div
          className="row"
          style={filterAttribute != 12 ? { display: "none" } : null}
        >
          <div
            className="sort-label"
            style={{ paddingLeft: "30px", paddingRight: "10px" }}
          >
            User inactive:
          </div>
          <h3 className="user-value">
            {dashBoardData?.user?.blocked_user ?? 0}
          </h3>
        </div>
      </Box>

      <Box>
        <div className="row">
          <div className="counting-key-label" style={{ padding: "10px" }}>
            Tổng số key đã mua:
          </div>
          <h3 className="counting-key-value">{totalPurchase?.totalKey ?? 0}</h3>
        </div>
      </Box>
      <Box>
        <div className="row">
          <div className="money-label" style={{ padding: "10px" }}>
            Tổng số giao dịch:
          </div>
          <h3 className="money-value">{totalPurchase?.totalPurchase ?? 0}</h3>
        </div>
        <div className="row">
          <div className="money-label" style={{ padding: "10px" }}>
            Tổng số tiền:
          </div>
          <h3 className="money-value">
            {((totalPurchase?.totalKey ?? 0) * 0.1).toFixed(1)} $
          </h3>
        </div>
      </Box>
    </Box>
  );
};

export default Dashboard;
