import axios from "axios";
import { BASE_URL } from "./ApiConstants";

axios.defaults.baseURL = BASE_URL;
axios.defaults.headers.post["Content-Type"] = "application/json";

var logoutAction;

axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  async function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.request.status === 403) {
      // window.localStorage.setItem("token", null);
      logoutAction();
    } else if (error.request.status === 400) {
      logoutAction();
    }
    return Promise.reject(error);
  }
);

/// Set this after F5 page to set token again
export const setAccessTokenHeader = (token) => {
  if (token != null) {
    axios.defaults.headers.common["TOKEN"] = token;
  } else {
    delete axios.defaults.headers.common["TOKEN"];
  }
};

export const setLogoutToAxios = (logout) => {
  if (logout) {
    logoutAction = logout;
  }
};

export default axios;
