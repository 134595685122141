import { Typography, Box, useTheme } from "@mui/material";
import { tokens } from "../../theme";

const MainTopbar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="100%"
      margin="30px"
    >
      <Typography
        variant="h2"
        color={colors.grey[100]}
        fontWeight="bold"
        sx={{ m: "0 0 5px 0" }}
      >
        MTC SUPER TOOL
      </Typography>
    </Box>
  );
};
export default MainTopbar;
