import { useEffect } from "react";
import {
  setAccessTokenHeader,
  setLogoutToAxios,
} from "../../network/ApiClient";
import { useAuth } from "../../hooks/useAuth";

const PurchasePage = () => {
  const { token, logout } = useAuth();

  useEffect(() => {
    if (token) {
      setAccessTokenHeader(token);
      setLogoutToAxios(logout);
    }
  }, [token, logout]);

  return <div>Purchase Page</div>;
};

export default PurchasePage;
