import { Button } from "@mui/base/Button";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useState } from "react";
import axios from "../../network/ApiClient";
import { blockUserApi, approveUserApi } from "../../network/ApiConstants";

function SwitchConfirm({ value, userId }) {
  const [open, setOpen] = useState(false);
  const [checked, setCheck] = useState(value);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (confirmed) => {
    setOpen(false);
    if (confirmed) {
      if (checked) {
        blockUser();
      } else {
        approveUser();
      }
    }
  };

  const blockUser = async () => {
    try {
      const response = await axios.post(
        blockUserApi,
        JSON.stringify({ user_id: userId })
      );
      console.log(response);
      if (response.data.status === "ok") {
        setCheck(!checked);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const approveUser = async () => {
    try {
      const response = await axios.post(
        approveUserApi,
        JSON.stringify({ user_id: userId })
      );
      console.log(response);
      if (response.data.status === "ok") {
        setCheck(!checked);
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <strong>
      <label class="switch">
        <input type="checkbox" checked={checked} onChange={handleClickOpen} />
        <span class="slider round"></span>
      </label>

      <Dialog open={open} onClose={() => handleClose(false)}>
        <DialogTitle>Xác nhận</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Bạn muốn {checked ? "block" : "kích hoạt"} user này?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleClose(true)}
            color="primary"
            variant="contained"
            autoFocus
          >
            Đồng ý
          </Button>
          <Button
            onClick={() => handleClose(false)}
            color="secondary"
            variant="outlined"
          >
            Hủy
          </Button>
        </DialogActions>
      </Dialog>
    </strong>
  );
}

export default SwitchConfirm;
