import { Button } from "@mui/base";
import { useState } from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

function ButtonDelete({ value, deleteAction }) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <Button onClick={handleClickOpen}>Delete</Button>
      <Dialog open={open} onClose={() => handleClose(false)}>
        <DialogTitle>Xác nhận</DialogTitle>
        <DialogContent>
          <DialogContentText>Bạn muốn xóa user này?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleClose(true);
              deleteAction(value.row.id);
            }}
            color="primary"
            variant="contained"
            autoFocus
          >
            Đồng ý
          </Button>
          <Button
            onClick={() => handleClose(false)}
            color="secondary"
            variant="outlined"
          >
            Hủy
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default ButtonDelete;
